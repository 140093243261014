@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);


:root {
  --applause-logo-color: #3d3f94;
  --applause-text-black: #260000;
}

.flex {
  display: flex;
}

.justify-center {
  display: flex;
  justify-content: center;
}

button {
  cursor: pointer;
}

.submitBtn {
  cursor: pointer !important;
  background: #179ed4 !important;
  border-radius: 10px !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  color: #ffffff !important;
  opacity: 0.9 !important;
}
.submitBtn:hover {
  background: #179ed4 !important;
  opacity: 1 !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

header > .applause-logo {
  display: flex;
  align-items: center;
  color: #3d3f94;
  color: var(--applause-logo-color);
  font-weight: 600;
  font-size: calc(var(--base-font-size) * 1.25);
  line-height: 22px;
  cursor: pointer;
}

header > .applause-logo > h3 {
  font-weight: 600;
  font-size: calc(var(--base-font-size) * 1.25);
  line-height: 22px;
  margin: 0;
}

